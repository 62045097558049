<template>
  <div class="pages">
    <div class="inside-nav">
      <el-tree
        :data="treeData"
        :props="defaultProps"
        :highlight-current = "highlight"
        accordion
        @node-click="handleNodeClick">
      </el-tree>
    </div>

    <div class="task_box">

      <!-- select 筛选 -->
      <div class="select_box">
        <div class="s_item">
          <div class="s_lable">日期：</div>
          <el-date-picker
            v-model="queryInfo.screen"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="btns" @click="getInitData">查询</div>
      </div>
      <!-- 图表 -->
      <div class="chart_box">
        <div
          id="chart"
          style="
            height: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
          "
        ></div>
      </div>

    </div>
  </div>
  
</template>
<script>
import * as echarts from 'echarts'
export default {
  data() {
    return {
      // 副 菜单
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      // 设备/筛选数据参数
      queryInfo: {
        device_id: '',
        screen: '',
      },
      // 图表数据
      chartData: [],
      highlight: true
    }
  },
  mounted() {
    // 获取页面传输过来的数据
    // console.log(this.$route.query.id)
    this.queryInfo.device_id = this.$route.query.id
    // 获取左侧二级菜单的数据
    this.getTreeTitleList()
    // 解决echarts图表放大溢出父容器
    this.$nextTick(() => {
      this.initChart() 
    })
    // 接口获取数据后，再为echarts赋值
    this.getInitData()
    
  },
  methods: {
    // 获取左侧二级菜单的数据
    async getTreeTitleList() {
      const res = await this.$http.get('Organize/left')
      console.log(res)
      if (res.code !== 1) return this.$message.error('获取组织列表失败！')
      // this.$message.success('获取设备列表成功！')
      this.treeData = res.data
    },

    // 点击 左侧二级菜单设备，重新加载此设备的图表
    handleNodeClick(data) {
      if(!data.children) {
        // 判断有没有下级，没有则为最底层数据
        this.queryInfo.device_id = data.id
        // 无刷新修改url中id参数
        this.$router.push(`${this.$route.path}?id=${data.id}`)
        // 如果点击的是 设备id 则获取此设备的全部数据，
        // this.queryInfo.screen = ''
        this.getInitData()
      }
    },

    // 获取图表数据
    async getInitData() {
      const res = await this.$http.post('device/energy_usage_histogram', this.queryInfo)
      console.log(res)
      if (res.code !== 1) return this.$message.error('获取组织列表失败！')
      // this.$message.success('获取设备列表成功！')
      this.chartData = res.data
      setTimeout(() => {
        this.initChart() 
      }, 1000)
    },

    // chart 图表
    initChart() {
      var chartDom = document.getElementById('chart');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: "rgba(255,255,255,0.2)",
          borderWidth: "0",
          textStyle: {
            color: "#ffffff" //设置文字颜色
          },
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: 'rgba(255, 255, 255, 0.2)'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        legend: {
          data: ['电量', '负荷'],
          textStyle: {
            color: '#5389A2'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: this.chartData.date,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              color: "#ffffff" // 文本颜色
            },
            axisLine: {
              show: false // 去除轴线
            },

            axisLine: {
              lineStyle: {
                color: '#5389A2' // 轴线颜色
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(83, 137, 162, 0.3)" // 分割线颜色
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'kw/h',
            min: 0,
            axisLabel: {
              formatter: '{value}',
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: 'rgba(12, 137, 173, 1)' // 轴线颜色
              },
            },
            splitLine: {
              lineStyle: {
                color: "#5389A2" // 分割线颜色
              }
            },
          },
          {
            type: 'value',
            name: '负荷',
            min: 0,
            axisLabel: {
              formatter: '{value} '
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: 'rgba(12, 137, 173, 1)' // 轴线颜色
              },
            },
            splitLine: {
              lineStyle: {
                color: "#5389A2" // 分割线颜色
              }
            },
          }
        ],
        series: [
          {
            name: '电量',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + 'kw/h';
              }
            },

            data: this.chartData.electricity,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#3ED090' },
                { offset: 1, color: 'rgba(62,208,144,0)' }
              ])
            },
            barCategoryGap: 10,
            barWidth: 19,
          },
          {
            name: '负荷',
            type: 'line',
            smooth: true,
            yAxisIndex: 1,
            color: 'rgba(0, 185, 238, 1)',
            symbolSize: 8,
            showSymbol: false,
            symbol:'circle',
            itemStyle: {
              normal: { 
                color:'rgba(7, 18, 31, 1)',
                borderColor: '#68D8FE',
                borderWidth: 1, 
                lineStyle:{
                  color: 'rgba(0, 185, 238, 1)',// 折线颜色设置为0，即只显示点，不显示折线
                  // width: 2
                }
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 负荷';
              }
            },
            data: this.chartData.load,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: 'rgba(255,172,40,0.2)' },
                  { offset: 1, color: 'rgba(255,172,40,0)' }
                ])
              },

            },
          }
        ]
      };
      option && myChart.setOption(option);
    },

  }
}
</script>
<style lang="scss" scoped>
 ::v-deep .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
   background: #68D8FE;
   color: #fff;
 }
  .pages {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  // 副菜单
  .inside-nav {
    width: 200px;
    height: calc(100% - 40px);
    margin-top: 20px;
    box-sizing: border-box;
    border: #61CDF1 2px solid;
    background-color: #1B284D;
    overflow-y: auto;
  }
  /* 左侧二级菜单滚动条 设置滚动条 */
  .inside-nav::-webkit-scrollbar {
    width: 4px;
  }
  .inside-nav::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #68D8FE;
  }
  .inside-nav::-webkit-scrollbar-track {
    border-radius: 0;
    background: #1B284D;
  }
  /* 设置滚动条  end */
  .el-tree {
    background-color: #1B284D;
    color: #ffffff;
  }
  ::v-deep .el-tree-node__label {
    font-size: 14px;
  }
  ::v-deep .el-tree-node__content {
    height: 30px;
  }
  ::v-deep .el-tree-node:focus>.el-tree-node__content {
    background-color: #1B284D;
    color: #68D8FE;
  }
  ::v-deep .el-tree-node__content:hover,  ::v-deep .el-upload-list__item:hover {
    background-color: #1B284D;
    color: #68D8FE;
  }


  // 内容
  .task_box {
    width: calc(100% - 220px);
    // select 筛选
    .select_box {
      margin-top: 40px;
      display: flex;
      align-items: center;
      padding: 15px;
      .s_item {
        display: flex;
        align-items: center;
        margin-right: 30px;
        .s_lable {
          font-size: 16px;
          color: #ffffff;
        }
      }
      .btns {
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #68D8FE;
        font-size: 16px;
        color: #ffffff;
        width: 80px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
      // charts 图表
  .chart_box {
    width: 100%;
    height: calc(100% - 200px);
  }
  }
   

</style>